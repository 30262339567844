<template>
  <div>
<!-- TODO здесь приводим графики выгруженные по апи-->
  </div>
</template>

<script>
export default {
  name: "PostStatisticCharts"
}
</script>

<style scoped>

</style>