<template>
  <div>
<!--        // TODO здесь приводим абсолютные цифры-->
  </div>
</template>

<script>
export default {
  name: "PostStatisticFooter"
}
</script>

<style scoped>

</style>