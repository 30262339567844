<template>
     <div class="container">
        <div class="header">
            <Header></Header>
        </div>
        <div class="content">
              <PostStatisticHeader>

              </PostStatisticHeader>
              <post-statistic-charts>

              </post-statistic-charts>

              <post-statistic-footer>

              </post-statistic-footer>
<!--           TODO здесь добавляем график-->
        </div>
    </div>

</template>

<script>
import Header from "../Assets/Header";
import PostStatisticHeader from "./PostStatisticHeader";
import PostStatisticCharts from "./PostStatisticCharts";
import PostStatisticFooter from "./PostStatisticFooter";

export default {
name: "PostStatistic",
  components: {
    Header,
    PostStatisticCharts,
    PostStatisticFooter,
    PostStatisticHeader
  }
}
</script>

<style scoped>

</style>