<template>
      <div class="post-statistic__header">
         <div class="post-statistic__data-type">
            <label>Выберите тип графика:</label>
            <select v-model="chartType">
                  <option value="all" >
                       Общий
                  </option>
                  <option value="social">
                        Соц.сеть по отдельности
                  </option>
            </select>
         </div>
         <div v-if="chartType === 'social'"
              class="post-statistic__social-select" >
            <label for="post-statistic-social-select">
                Выберите социальную сеть:
            </label>
            <select id="post-statistic-social-select">
                <option>VK</option>
            </select>

         </div>
         <div class="post-statistic__period-select">
           <div class="post-statistic__period-wrapper">
                <label for="post-statistic-period-start">Выберите начало периода</label>
                <input id="post-statistic-period-start" type="date">
           </div>
           <div class="post-statistic__period-wrapper">
                <label for="post-statistic-period-end">Выберите начало периода</label>
                <input id="post-statistic-period-end" type="date">
           </div>

         </div>

         <button class="post-statistic__header-button">Загрузить данные</button>
      </div>
</template>

<script>
export default {
  name: "PostStatisticHeader",
  data() {
    return {
      chartType: 'all'
    }
  }
}
</script>

<style scoped>

</style>